const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel', 'Engine'];
const extraFitmentFields = [];
const ignoreFields = ['Universal', 'Fitment'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];

const brandSelectionPageUrl = '/brands-list/';
const categorySelectionPageUrl = '/category-list/';

function getLocalPreselection(pageType, defaults) {
  if (defaults.termFromBreadcrumbs === 'Categories') {
    return;
  }
  if (defaults.termFromBreadcrumbs.startsWith('Categories')) {
    const trimmedTermFromBreadcrumbs = defaults.termFromBreadcrumbs.replace('Categories>', '');
    return [
      {
        field: 'category',
        term: trimmedTermFromBreadcrumbs,
        treeLevel: trimmedTermFromBreadcrumbs.split('>').length - 1,
      },
    ];
  }
  return defaults.getter(pageType);
}

export default {
  platform: 'bigcommerce',
  defaultView: 'productGrid',
  getLocalPreselection,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    isVehicleSelectionIsolated: true,
    expectResponseRedirect: true,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: ['body.page-type-brand', 'body.page-type-category']
        .map((body) => `${body} .page-content`)
        .join(),
      template: 'MainContent',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'ItemPopularity:desc': 'Best Sellers',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
      facetToggleConfig: {
        initToggled: true,
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: baseFitmentFields,
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: ['body.page-type-brand', 'body.page-type-category']
        .map((body) => `${body} .page-sidebar`)
        .join(),
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'SearchBox',
      location: '.search-container',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '#cm_SearchBoxDialogButton',
        class: 'cm_search-box-root__dialog_open-button-container cm_desktop-hide',
      },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'HomeSearchBox',
      type: 'SearchBox',
      location: {
        replace: '.search-container .partnumber-search-input',
        class: 'cm_search-box-root__home',
      },
      template: 'SearchBox',
      disableDropdown: true,
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '.search-container .vehicle-selector',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 760,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysActive: true,
      initCollapsed: true,
      columnBreakpoint: 760,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'SingleVehicleGarage',
      location: {
        replace: '#cm_SingleVehicleGarage',
        class: 'cm_single-vehicle-garage cm_single-vehicle-garage_desktop header-cart cm_mobile-hide',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'SingleVehicleGarage_mobile',
      type: 'SingleVehicleGarage',
      location: {
        replace: '#cm_SingleVehicleGarage_mobile',
        class: 'cm_single-vehicle-garage cm_single-vehicle-garage_mobile cm_desktop-hide',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'Garage',
      location: {
        insertAfter: 'header .menu-right .contact',
        wrapper: 'a',
        class: 'header-cart cm_garage-container',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        insertAfter: '.productView-description .tabs .tab:first-child',
        class: 'tab cm_fitment-table__tab',
        wrapper: 'li',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: {
        id: 'tab-fitment',
        class: 'tab-content',
        insertAfter: '.tabs-contents .tab-content',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands',
      template: 'facetTiles/hierarchicalContainer',
      isAlwaysActive: true,
      initCollapsed: true,
      columnBreakpoint: 760,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_categories',
      template: 'facetTiles/hierarchicalContainer',
      isAlwaysActive: true,
      initCollapsed: true,
      columnBreakpoint: 760,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
