
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonAdaptableRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBox(function () {
            return _createElement('div', { 'className': 'multi-search quickSearch cm_searchBox' }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [
                [this.searchInput(function () {
                        return _createElement('div', { 'className': 'search-box cm_searchInput' }, 'Search over 1M+ parts');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })],
                _createElement('span', {
                    'className': 'cm_search-box_clear-container',
                    'key': '1062'
                }, this.inputNotEmpty ? _createElement('span', {
                    'className': 'cm_search-box_clear',
                    'onClick': this.clearInput,
                    'key': '419'
                }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
                _createElement('div', {
                    'className': 'cm_search-box_submit search-button button',
                    'title': 'Search',
                    'aria-label': 'search button',
                    'data-cm-role': 'add-query',
                    'key': '1064'
                }, [_createElement('svg', {
                        'viewBox': '0 0 118.783 118.783',
                        'id': 'icon-search',
                        'key': '7280'
                    }, _createElement('path', { 'd': 'M115.97,101.597L88.661,74.286c4.64-7.387,7.333-16.118,7.333-25.488c0-26.509-21.49-47.996-47.998-47.996   S0,22.289,0,48.798c0,26.51,21.487,47.995,47.996,47.995c10.197,0,19.642-3.188,27.414-8.605l26.984,26.986   c1.875,1.873,4.333,2.806,6.788,2.806c2.458,0,4.913-0.933,6.791-2.806C119.72,111.423,119.72,105.347,115.97,101.597z    M47.996,81.243c-17.917,0-32.443-14.525-32.443-32.443s14.526-32.444,32.443-32.444c17.918,0,32.443,14.526,32.443,32.444   S65.914,81.243,47.996,81.243z' }))])
            ]), [this.dropdown(function () {
                    function repeatSections1(sections, sectionsIndex) {
                        return [sections(function () {
                                function repeatItems1(items, itemsIndex) {
                                    return [items(function () {
                                            function mergeProps_text(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function mergeProps_product(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function onError1_facetValue(e) {
                                                e.target.classList.add('cm_hide');
                                            }
                                            function mergeProps_facetValue(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                                'src': this.imageOrDefault(this.images_image_thumbnail1 || this.image_thumbnail || this.images_image_standard1 || this.image_standard),
                                                'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.mpn) || this.removeHTML(this.name)),
                                                'onError': this.onImageError
                                            })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.name } })), _createElement('div', { 'className': 'price' }, this.formatPrice(this.price)))) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                                'className': 'image-block',
                                                'key': '35'
                                            }, _createElement('img', {
                                                'src': this.imageUrl,
                                                'alt': 'Image of ' + this.facetValue,
                                                'onError': onError1_facetValue.bind(this)
                                            })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                                'className': 'hit-count',
                                                'key': '335'
                                            }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : '') }, this.sectionTitle ? _createElement('div', {
                                    'className': 'section-title-div',
                                    'key': '94'
                                }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_items' },
                                    _map(this.items, repeatItems1.bind(this))
                                ]));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_sections' },
                        _map(this.sections, repeatSections1.bind(this))
                    ]), this.browseAllButton ? [[this.browseAllButton(function () {
                                return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]] : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown","cm:searchBox"]